import {
  TelegramWebApp,
  TelegramSchema,
  TgThemeParamsSchema,
  TgPopupButton,
} from './types';
import { ref, Ref } from 'vue';

declare global {
  interface Window {
    Telegram: { WebApp: TelegramSchema };
  }
}

function semverGte(a: string, b: string): boolean {
  const res = a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  return res >= 0;
}

export class WebApp implements TelegramWebApp {
  private tg: TelegramSchema;
  private readonly debugInitData: string;
  private readonly initialized: Ref<boolean>;

  constructor() {
    this.tg = <TelegramSchema>{};
    this.initialized = ref(false);
    this.debugInitData = process.env?.VUE_APP_TG_DEBUG_DATA || '';
  }

  _setScriptLoaded() {
    this.tg = window.Telegram.WebApp;
    this.tg.ready();

    this.initialized.value = true;
  }

  isReady(): Ref<boolean> {
    return this.initialized;
  }

  expandWindow(): void {
    this.tg.expand();
  }

  closeApp(): void {
    this.tg.close();
  }

  disableVerticalSwipes(): void {
    if (semverGte(this.tg.version, '7.7')) {
      this.tg.disableVerticalSwipes();
    }
  }

  getInitData(): string {
    if (this.debugInitData.length > 0) {
      return this.debugInitData;
    }
    return this.tg.initData;
  }

  getLanguageCode(): string {
    return this.tg.initDataUnsafe?.user?.languageCode || 'ru';
  }

  getColorScheme(): string {
    return this.tg.colorScheme;
  }

  getColorTheme(): TgThemeParamsSchema {
    return this.tg.themeParams;
  }

  setHeaderColor(color: string): void {
    this.tg.setHeaderColor(color);
  }

  setBackgroundColor(color: string): void {
    this.tg.setBackgroundColor(color);
  }

  showMainButton(text: string): void {
    this.tg.MainButton.setParams({
      text: text,
    });
    this.tg.MainButton.show();
  }

  hideMainButton(): void {
    this.tg.MainButton.hide();
  }

  onMainButtonClick(cb: () => void): void {
    this.tg.MainButton.onClick(cb);
  }

  offMainButtonClick(cb: () => void): void {
    this.tg.MainButton.offClick(cb);
  }

  showBackButton(): void {
    this.tg.BackButton.show();
  }

  hideBackButton(): void {
    this.tg.BackButton.hide();
  }

  onBackButtonClick(cb: () => void): void {
    this.tg.BackButton.onClick(cb);
  }

  offBackButtonClick(cb: () => void): void {
    this.tg.BackButton.offClick(cb);
  }

  showSettingsButton(): void {
    if (semverGte(this.tg.version, '7.0')) {
      this.tg.SettingsButton.show();
    }
  }

  hideSettingsButton(): void {
    if (semverGte(this.tg.version, '7.0')) {
      this.tg.SettingsButton.hide();
    }
  }

  onSettingsButtonClick(cb: () => void): void {
    if (semverGte(this.tg.version, '7.0')) {
      this.tg.SettingsButton.onClick(cb);
    }
  }

  offSettingsButtonClick(cb: () => void): void {
    if (semverGte(this.tg.version, '7.0')) {
      this.tg.SettingsButton.offClick(cb);
    }
  }

  enableClosingConfirmation(): void {
    this.tg.enableClosingConfirmation();
  }

  openLink(url: string): void {
    this.tg.openLink(url);
  }

  openTelegramLink(url: string): void {
    this.tg.openTelegramLink(url);
  }

  showAlert(msg: string): void {
    if (!semverGte(this.tg.version, '6.2')) {
      alert(msg);
      return;
    }
    this.tg.showAlert(msg);
  }

  showPopup(
    title: string,
    message: string,
    buttons: TgPopupButton[],
    cb: (id: string) => void
  ): void {
    if (!semverGte(this.tg.version, '6.2')) {
      const ok = confirm(message);
      cb(ok ? 'approve' : 'reject');
      return;
    }
    this.tg.showPopup({ title, message, buttons }, cb);
  }
}
