import { defineComponent, PropType } from 'vue';
import styles from './index.css?module';

export enum VARIANT {
  PRIMARY,
  SECONDARY,
  TRANSPARENCY,
  DANGER,
}

export enum SIZE {
  INHERIT,
  SMALL,
  BASE,
  LARGE,
}

export default defineComponent({
  props: {
    size: {
      type: Number as PropType<SIZE>,
      default: SIZE.BASE,
    },
    variant: {
      type: Number as PropType<VARIANT>,
      default: VARIANT.PRIMARY,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
    },
  },

  setup(props, { slots, emit }) {
    const variantStyle = (): string => {
      switch (props.variant) {
        case VARIANT.PRIMARY:
          return styles.btnThemePrimary;
        case VARIANT.SECONDARY:
          return styles.btnThemeSecondary;
        case VARIANT.DANGER:
          return styles.btnThemeDanger;
        case VARIANT.TRANSPARENCY:
          return '';
      }
    };

    const sizeStyle = () => {
      switch (props.size) {
        case SIZE.SMALL:
          return styles.btnSizeSmall;
        case SIZE.BASE:
          return styles.btnSizeBase;
        case SIZE.LARGE:
          return styles.btnSizeLarge;
        case SIZE.INHERIT:
          return '';
      }
    };

    return () => (
      <button
        class={[
          styles.btn,
          props.stretch && styles.btnStretch,
          variantStyle(),
          sizeStyle(),
        ]}
        disabled={props.disabled}
        onClick={(e) => emit('click', e)}
      >
        {slots?.default?.()}
      </button>
    );
  },
});
