import { defineComponent, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAppStore, useAuthStore, useToast } from '@/store';
import { Center, Text, TextColor, TextSize } from '@/components/ui';
import { Vue3Lottie } from 'vue3-lottie';
import { TOAST_TYPE } from '@/store/toast';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const toastStore = useToast();

    const updateBackButton = () => {
      appStore.hideBackButton();
    };

    const updateMainButton = () => {
      appStore.hideMainButton();
    };

    const updateButtons = () => {
      updateBackButton();
      updateMainButton();
    };

    onMounted(async () => {
      updateButtons();
      const taskId = await authStore.deleteAccount();
      if (taskId.length <= 0) {
        toastStore.add(TOAST_TYPE.ERROR, t('views.deleteAccount.error'));
      }
    });

    return () => (
      <Center>
        <div class={'flex flex-col items-center'}>
          <Vue3Lottie
            class={'w-40 h-40'}
            height={160}
            width={160}
            animationData={require('@/assets/images/duck_private.json')}
          />
          <Text size={TextSize.H4} color={TextColor.SECONDARY}>
            {t('views.deleteAccount.info')}
          </Text>
          <Text size={TextSize.H4} color={TextColor.SECONDARY}>
            {t('views.deleteAccount.note')}
          </Text>
        </div>
      </Center>
    );
  },
});
