export default {
  bgApp: '#000',

  bgPrimary: '#1c1c1d',
  bgPrimaryDanger: '#FE3B30',

  bgSecondary: '#373739',
  bgSecondaryLight: '#373739',

  textWhite: '#ffffff',
  textPrimary: '#ffffff',
  textSecondary: '#8e8e93',
  textSecondaryDark: '#636366',

  textHighlight: '#037EE5',
  textHighlightDanger: '#FE3B30',

  bgField: '#1c1c1d',
  bgFieldSecondary: '#272728',
};
