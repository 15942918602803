import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useEnv } from '@/composables';
import UserDetail from '@/views/user/detail/detail';
import UserProfile from '@/views/user/profile/Profile';
import Ads from '@/views/ads/Ads';
import Settings from '@/views/settings/Settings';
import SettingAccount from '@/views/settings/account/SettingAccount';
import Unauthenticated from '@/views/system/Unauthenticated';
import DeleteAccount from '@/views/system/DeleteAccount';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/user',
    name: 'user',
    component: UserDetail,
  },
  {
    path: '/user/profile',
    name: 'user.profile',
    component: UserProfile,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/settings/account',
    name: 'settings.account',
    component: SettingAccount,
  },
  {
    path: '/',
    name: 'ads',
    component: Ads,
  },
  {
    path: '/error/unauthenticated',
    name: 'error.unauthenticated',
    component: Unauthenticated,
  },
  {
    path: '/system/delete',
    name: 'system.delete',
    component: DeleteAccount,
  },
];

const scrollBehavior = (to: any, from: any, savedPosition: any) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(useEnv().baseUrl()),
  routes,
  scrollBehavior,
});

export default router;
