import { defineComponent, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { Vue3Lottie } from 'vue3-lottie';
import { Center, Text, TextColor, TextSize } from '@/components/ui';
import { useAppStore } from '@/store';
import { useTelegramWebApp } from '@/plugins';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const appStore = useAppStore();
    const tgWebApp = useTelegramWebApp();

    const updateBackButton = () => {
      appStore.hideBackButton();
    };

    const updateMainButton = () => {
      appStore.showMainButton(
        t('views.unauthenticated.openChatBtnText'),
        () => {
          tgWebApp.openTelegramLink('https://t.me/unlyDatingBot');
          tgWebApp.closeApp();
        }
      );
    };

    onMounted(async () => {
      updateBackButton();
      updateMainButton();
    });

    return () => (
      <Center>
        <div class={'flex flex-col items-center'}>
          <Vue3Lottie
            class={'w-40 h-40'}
            height={160}
            width={160}
            animationData={require('@/assets/images/duck_profile.json')}
          />
          <Text size={TextSize.H4} color={TextColor.SECONDARY}>
            {t('views.unauthenticated.info')}
          </Text>
          <Text size={TextSize.H4} color={TextColor.SECONDARY}>
            {t('views.unauthenticated.note')}
          </Text>
        </div>
      </Center>
    );
  },
});
