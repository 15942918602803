import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useRouter, useRoute, LocationQueryValue } from 'vue-router';
import {
  Center,
  Loader,
  LoaderSize,
  DictionaryValue,
  Text,
  TextSize,
  TextColor,
} from '@/components/ui';
import { Vue3Lottie } from 'vue3-lottie';
import { Filter, FilterData, AdList, RangeValue } from '@/components/ads';
import {
  useAdStore,
  useAuthStore,
  useAppStore,
  useDictionaryStore,
} from '@/store';
import { useEndOfScroll, useRouteBuilder, useSemaphore } from '@/composables';
import { useI18n } from 'vue-i18n';
import { DistanceType } from '@/api/dictionary';

export default defineComponent({
  setup: () => {
    const { t } = useI18n();
    const adStore = useAdStore();
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const dictionaryStore = useDictionaryStore();
    const route = useRoute();
    const router = useRouter();
    const routeBuilder = useRouteBuilder();
    const semaphore = useSemaphore();
    const { isEndOfScroll, checkEndOfScroll } = useEndOfScroll(800);

    const adListEl = ref({} as HTMLElement);
    const filterModel = ref({} as FilterData);

    const currentPage = ref(1);
    const hasMorePage = ref(false);
    const initialized = ref(false);
    const loadingList = ref(false);

    const hasActiveAd = computed((): boolean => {
      return adStore?.ad?.isActive ?? false;
    });

    const hasSharedGeo = computed((): boolean => {
      return authStore?.profile?.hasSharedGeo ?? false;
    });

    const initFilter = async () => {
      const resp = await dictionaryStore.getValues({
        cityId: (route?.query?.cityId || '0') as string,
        desire: (route?.query?.desire || '') as string,
        distance: (route?.query?.distance || '') as string,
      });
      if (!resp.ok) {
        return;
      }

      const values = resp.payload;

      filterModel.value = {
        city: {
          key: values?.city?.id ?? values.distance?.slug,
          name: values?.city?.name ?? values.distance?.name,
        } as DictionaryValue,
        desire: {
          key: values?.desire?.slug,
          name: values?.desire?.name,
        } as DictionaryValue,
        age: {
          from: route.query.ageFrom ?? 0,
          to: route.query.ageTo ?? 0,
        } as RangeValue,
        height: {
          from: route.query.heightFrom ?? 0,
          to: route.query.heightTo ?? 0,
        } as RangeValue,
        hasPhoto: route.query.hasPhoto === 'true',
        newest: route.query.newest === 'true',
        isOnline: route.query.isOnline === 'true',
      };
    };

    const getFilterCity = (data: FilterData): string => {
      const val = data?.city?.key ?? '0';
      if (isDistanceType(val)) {
        return '0';
      }
      return val;
    };

    const getFilterDistance = (data: FilterData): string => {
      const val = data?.city?.key ?? '';
      if (isDistanceType(val)) {
        return val;
      }
      return '';
    };

    const isDistanceType = (val: string): boolean => {
      const values = Object.values(DistanceType) as string[];
      return values.includes(val);
    };

    const loadMoreAdList = async () => {
      if (!hasMorePage.value || !semaphore.tryAcquire()) {
        return;
      }

      const resp = await adStore.loadMoreAdList({
        page: currentPage.value + 1,
        cityId: getFilterCity(filterModel.value),
        distance: getFilterDistance(filterModel.value),
        desires: filterModel.value?.desire?.key
          ? [filterModel.value?.desire?.key]
          : [],
        ageFrom: filterModel.value?.age?.from ?? 0,
        ageTo: filterModel.value?.age?.to ?? 0,
        heightFrom: filterModel.value?.height?.from ?? 0,
        heightTo: filterModel.value?.height?.to ?? 0,
        hasPhoto: filterModel.value?.hasPhoto ?? false,
        newest: filterModel.value?.newest ?? false,
        isOnline: filterModel.value?.isOnline ?? false,
      });

      if (resp.ok) {
        currentPage.value += 1;
        hasMorePage.value = resp.payload.hasMore;
      }

      semaphore.release();
    };

    const reloadAdList = async () => {
      loadingList.value = true;

      const resp = await adStore.loadAdList({
        page: 1,
        cityId: getFilterCity(filterModel.value),
        distance: getFilterDistance(filterModel.value),
        desires: filterModel.value?.desire?.key
          ? [filterModel.value?.desire?.key]
          : [],
        ageFrom: filterModel.value?.age?.from ?? 0,
        ageTo: filterModel.value?.age?.to ?? 0,
        heightFrom: filterModel.value?.height?.from ?? 0,
        heightTo: filterModel.value?.height?.to ?? 0,
        hasPhoto: filterModel.value?.hasPhoto ?? false,
        newest: filterModel.value?.newest ?? false,
        isOnline: filterModel.value?.isOnline ?? false,
      });

      if (resp.ok) {
        currentPage.value = 1;
        hasMorePage.value = resp.payload.hasMore;
      }

      loadingList.value = false;
      initialized.value = true;
    };

    const updateBackButton = () => {
      appStore.hideBackButton();
    };

    const updateMainButton = () => {
      appStore.showMainButton(t('views.ads.mainBtn'), () => {
        router.push(routeBuilder.userDetail());
      });
    };

    const updateButtons = () => {
      updateMainButton();
      updateBackButton();
    };

    onMounted(async () => {
      updateButtons();
      const resp = await adStore.loadAd();
      if (resp.ok && resp.payload.isActive) {
        await authStore.loadProfile();
        await initFilter();
        await reloadAdList();
      }

      initialized.value = true;
      document.body.classList.add('fixScreenScroll');
    });

    onUnmounted(() => {
      document.body.classList.remove('fixScreenScroll');
    });

    watch(filterModel, async () => {
      const cityId = getFilterCity(filterModel.value);
      const distance = getFilterDistance(filterModel.value);
      const desire = filterModel.value?.desire?.key ?? '';
      const ageFrom = filterModel.value?.age?.from ?? 0;
      const ageTo = filterModel.value?.age?.to ?? 0;
      const heightFrom = filterModel.value?.height?.from ?? 0;
      const heightTo = filterModel.value?.height?.to ?? 0;
      const hasPhoto = filterModel.value?.hasPhoto ?? false;
      const isOnline = filterModel.value?.isOnline ?? false;
      const isNewest = filterModel.value?.newest ?? false;

      const queryDesires = route.query.desires ?? ([] as LocationQueryValue[]);
      const desireIsEqual =
        JSON.stringify([desire]) == JSON.stringify(queryDesires);

      const filterChanged =
        !desireIsEqual ||
        cityId != route.query.cityId ||
        distance != route.query.distance ||
        ageFrom != +(route.query.ageFrom ?? 0) ||
        ageTo != +(route.query.ageTo ?? 0) ||
        heightFrom != +(route.query.heightFrom ?? 0) ||
        heightTo != +(route.query.heightTo ?? 0) ||
        hasPhoto != (route.query.hasPhoto === 'true') ||
        isNewest != (route.query.newest === 'true') ||
        isOnline != (route.query.isOnline === 'true');

      if (filterChanged) {
        await router.replace(
          routeBuilder.adsList(
            cityId,
            distance,
            [desire],
            ageFrom,
            ageTo,
            heightFrom,
            heightTo,
            hasPhoto.toString(),
            isOnline.toString(),
            isNewest.toString()
          )
        );
        await reloadAdList();
      }
    });

    const onEndOfScroll = async () => {
      if (!adListEl.value) {
        return;
      }

      checkEndOfScroll(adListEl.value);
      if (isEndOfScroll.value) {
        await loadMoreAdList();
      }
    };

    const onOpenGallery = () => {
      appStore.setNightMode();
      appStore.hideMainButton();
    };

    const onCloseGallery = () => {
      appStore.resetHeaderColor();
      updateButtons();
    };

    const showAdList = computed(() => {
      return (
        hasActiveAd.value && adStore.adList.length > 0 && !loadingList.value
      );
    });

    const showEmptyState = computed(() => {
      return (
        !loadingList.value && hasActiveAd.value && adStore.adList.length <= 0
      );
    });

    const showPublishState = computed(() => {
      return !loadingList.value && !hasActiveAd.value;
    });

    return () => (
      <>
        {!initialized.value ? (
          <Center>
            <Loader size={LoaderSize.XL} />
          </Center>
        ) : (
          <>
            <Filter
              v-model={filterModel.value}
              showDistanceFilters={hasSharedGeo.value}
              onUpdatedButtonSate={updateButtons}
            />

            {showAdList.value && (
              <div
                ref={adListEl}
                class={'mt-[3.5rem] pb-4 h-full overflow-x-hidden'}
                onScroll={onEndOfScroll}
              >
                <div class={'h-[0.5rem]'}></div>
                <AdList
                  items={adStore.adList}
                  onOpenGallery={onOpenGallery}
                  onCloseGallery={onCloseGallery}
                />
              </div>
            )}

            {!showAdList.value && loadingList.value && (
              <Center>
                <Loader size={LoaderSize.XL} />
              </Center>
            )}

            {showEmptyState.value && (
              <Center>
                <div class={'flex flex-col items-center'}>
                  <Vue3Lottie
                    class={'w-40 h-40'}
                    height={160}
                    width={160}
                    animationData={require('@/assets/images/duck_not_found.json')}
                  />
                  <Text size={TextSize.H4} color={TextColor.SECONDARY}>
                    {t('views.ads.notFound')}
                  </Text>
                  <Text size={TextSize.H4} color={TextColor.SECONDARY}>
                    {t('views.ads.tryChangeFilter')}
                  </Text>
                </div>
              </Center>
            )}

            {showPublishState.value && (
              <Center>
                <div class={'flex flex-col items-center'}>
                  <Vue3Lottie
                    class={'w-40 h-40'}
                    height={160}
                    width={160}
                    animationData={require('@/assets/images/duck_kiss.json')}
                  />
                  <Text size={TextSize.H4} color={TextColor.SECONDARY}>
                    {t('views.ads.needPublishAd')}
                  </Text>
                  <Text size={TextSize.H4} color={TextColor.SECONDARY}>
                    {t('views.ads.gotoProfile')}
                  </Text>
                </div>
              </Center>
            )}
          </>
        )}
      </>
    );
  },
});
