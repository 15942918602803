import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useTelegramWebApp } from '@/plugins';
import { useAppStore } from '@/store';
import { useI18n } from 'vue-i18n';
import {
  Container,
  Button,
  Text,
  BtnSize,
  BtnVariant,
  TextColor,
} from '@/components/ui';
import { TgPopupButton } from '@/plugins/telegram/types';
import { useRouteBuilder } from '@/composables';

export default defineComponent({
  setup: () => {
    const { t } = useI18n();
    const router = useRouter();
    const appStore = useAppStore();
    const tgWebApp = useTelegramWebApp();
    const routeBuilder = useRouteBuilder();

    const onDeleteAccount = () => {
      tgWebApp.showPopup(
        t('views.settingAccount.attention'),
        t('views.settingAccount.toastDeleteAcc'),
        [
          {
            id: 'reject',
            text: t('views.settingAccount.cancelBtnTxt'),
            type: 'cancel',
          },
          {
            id: 'approve',
            text: t('views.settingAccount.deleteBtnTxt'),
            type: 'destructive',
          },
        ] as TgPopupButton[],
        async (id: string) => {
          if (id !== 'approve') {
            return;
          }
          await router.push(routeBuilder.deleteAccount());
        }
      );
    };

    const updateBackButton = () => {
      appStore.showBackButton(() => {
        router.back();
      });
    };

    const updateMainButton = () => {
      appStore.hideMainButton();
    };

    const updateButtons = () => {
      updateBackButton();
      updateMainButton();
    };

    onMounted(async () => {
      updateButtons();
    });

    return () => (
      <Container class={'mt-8'}>
        <Button
          class={'w-full'}
          size={BtnSize.BASE}
          variant={BtnVariant.DANGER}
          onClick={onDeleteAccount}
        >
          <Text color={TextColor.WHITE}>
            {t('views.settingAccount.deleteAccountBtnText')}
          </Text>
        </Button>
      </Container>
    );
  },
});
